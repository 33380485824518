<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-account-list' }">Dashboard</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5">
                <h1 class="text-h4 font-weight-light">Accounts</h1>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">My Accounts</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Activities in progress ({{ interactionList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>

                    <!-- <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">{{label}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon color="purple" @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-toolbar> -->
                        <!-- <v-divider></v-divider> -->
                        <v-list dense nav>
                            <v-list-item v-if="interactionList.length === 0">None.</v-list-item>
                            <v-list-item v-for="item in interactionList" v-bind:key="item.id" class="my-2" @click="onClickItem(item)">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title>
                                        {{ item.type }} | {{ JSON.stringify(item.content) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="item.duration_since_created">Created {{ item.duration_since_created }} ago</span>
                                        <span v-if="item.duration_since_created && item.duration_until_expires"> | </span>
                                        <span v-if="item.duration_until_expires">Expires in {{ item.duration_until_expires }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toText } from '@libertyio/time-util-js';

export default {
    components: {
    },
    data: () => ({
        error: null,
        interactionList: [],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            focus: (state) => state.focus,
            // interactionList: (state) => state.interactionList,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session?.isAuthenticated;
        },
    },
    watch: {
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                console.log('interactionlist.vue: isAuthenticated is now set');
                this.init();
            }
        },
        focus() {
            this.loadInteractionList();
        },
    },
    methods: {
        async init() {
            console.log('interactionlist.vue: init');
            await this.loadInteractionList();
        },
        onClickItem(item) {
            if (item.type === 'customer_connect') {
                if (this.brandprofile) {
                    this.$nav.push({ name: 'brand-connect', query: { i: item.id } });
                } else {
                    this.$nav.push({ name: 'connect', query: { i: item.id } });
                }
            } else {
                if (this.brandprofile) { // eslint-disable-line no-lonely-if
                    this.$nav.push({ name: 'brand-interaction', query: { i: item.id } });
                } else {
                    this.$nav.push({ name: 'interaction', query: { i: item.id } });
                }
            }
        },
        async loadInteractionList() {
            console.log('interactionlist.vue: loadInteractionList');
            try {
                this.$store.commit('loading', { loadInteractionList: true });
                // TODO: rename this to session interaction search, since we're checking what's associated to this session; we're not actually searching all interactions in the database and user cannot see any interactions not part of this session
                const result = await this.$client.site(this.brandprofile).interaction.search();
                // convert timestamps to relative time durations
                const now = Date.now();
                for (let i = 0; i < result.list.length; i += 1) {
                    if (Number.isInteger(result.list[i].created_on)) {
                        result.list[i].duration_since_created = toText(now - result.list[i].created_on);
                    }
                    if (Number.isInteger(result.list[i].not_after)) {
                        result.list[i].duration_until_expires = toText(result.list[i].not_after - now);
                    }
                }
                this.interactionList = result.list;
            } catch (err) {
                console.error('connect.vue: failed to load organization list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadInteractionList: false });
            }
        },
    },
    mounted() {
        console.log('interactionlist.vue: mounted');
        if (this.isAuthenticated) {
            console.log('interactionlist.vue: isAuthenticated');
            this.init();
        }
    },
};
</script>
